<template>
  <div class="w-full h-full flex flex-col">
    <SendMessageModal
      v-if="sendMessageModalOpened"
      @close="sendMessageModalOpened = false"
      :user="selectedAmb" />

    <SendBulkMessageModal
      v-if="sendBulkMessageModalOpened"
      @close="sendBulkMessageModalOpened = false"
      :ambassadors="displayedAmbassadors"
      :selectedAmbassadors="selectedRows" />

    <RequestOutreachModal
      v-model="requestOutreachModalOpened"
      :user="selectedAmb" />

    <InviteAmbassadorModal
      v-if="inviteAmbassadorModalOpened"
      @close="inviteAmbassadorModalOpened = false" />

    <ManageOrderModal
      v-if="manageOrderModalOpened"
      @close="manageOrderModalOpened = false"
      :ambassadors="ambassadors" />

    <EditProfileModal
      v-if="editProfileModalOpened"
      @close="editProfileModalOpened = false"
      @rotate-profile="rotateProfile($event)"
      :user="selectedAmb" />

    <EditStoryModal
      v-if="storyModalOpened"
      @close="toggleAmbassadorStoryModal"
      @updateTextStory="updateAmbassadorStory"
      :user="selectedAmb" />

    <ProfileCard
      v-if="profileCardOpened"
      @close="profileCardOpened = false"
      :user="selectedAmb" />

    <common-popup
      v-if="deleteAmbassadorPopupOpened"
      v-bind="deleteAmbassadorPopupProps"
      @first-button="deleteAmbassadorPopupOpened = false"
      @second-button="deleteAmbassador"
      @close="deleteAmbassadorPopupOpened = false"
      height="h-max" />

    <!-- Header -->
    <div as="div" class="z-20 w-full bg-white pt-8 relative lg:sticky lg:top-0">
      <div class="flex flex-col lg:flex-row justify-between lg:items-start">
        <span class="mr-10 mb-5 lg:mb-0 w-full lg:w-3/5">
          <h1 class="text-2xl font-semibold mb-2">
            {{ $lang.ambassadorPage_HeaderTitle }}
          </h1>
        </span>
        <div as="div" class="flex flex-col lg:flex-row">
          <!-- Search Input -->
          <div
            class="relative flex h-12 lg:mr-3 mb-3 lg:mb-0 flex-shrink-0 lg:w-52">
            <common-input
              class="flex-none"
              :placeholder="$lang.general_SearchPlaceholder"
              variant="search"
              v-model="searchParameter"
              icon>
              <img :src="require('@/assets/search-icon.svg')" class="h-4 w-4" />
            </common-input>
          </div>

          <!-- Quick Menu -->
          <div class="flex flex-shrink-0">
            <common-dropdown
              class="lg:ml-3"
              variant="secondary"
              :options="quickMenuOptions"
              @input="handleQuickMenuClick($event)"
              :button="{ label: $lang.general_QuickMenuLabel, icon: true }"
              alignDropdownLeft
              maxHeight>
              <template slot="icon">
                <img :src="require('@/assets/lightning-icon-white.svg')" />
              </template>
            </common-dropdown>
          </div>
        </div>
      </div>
      <div class="flex mt-4 mb-2 justify-between lg:items-end">
        <!-- Bulk Actions and Filters -->
        <div class="flex">
          <common-dropdown
            placeholder="Bulk Actions"
            class="mr-4"
            variant="secondary"
            :options="bulkActionsDropdownOptions"
            @input="handleBulkDropdownClick($event)"
            groups
            maxHeight
            fixedPlaceholder />
          <filter-dropdown @update-filter="updateFilter($event)" />
        </div>

        <!-- Data Refresh -->
        <div class="flex items-center text-xs text-grey">
          <p class="hidden lg:block" :class="{ 'animate-pulse': !lastFetched }">
            {{ dataFetchDisplayText }}
          </p>

          <img
            :src="require('@/assets/spinner-icon.svg')"
            class="lg:ml-2 h-6 w-6 lg:h-3 lg:w-3 cursor-pointer focus:outline-none"
            :class="{ 'animate-spin': fetching }"
            content="Fetch most recent data"
            v-tippy="{ placement: 'bottom', arrow: true, maxWidth: '500px' }"
            @click="fetchMostRecent" />
        </div>
      </div>
    </div>

    <!-- Ambassador Table -->
    <section class="flex-grow">
      <div class="grid">
        <div as="div">
          <table class="w-full">
            <!-- Columns -->
            <thead class="text-grey text-left text-xs select-none">
              <tr>
                <th
                  scope="col"
                  class="px-2 py-3 text-left text-xs font-medium text-grey-500 whitespace-nowrap">
                  <div class="flex items-center space-x-3">
                    <input
                      type="checkbox"
                      v-model="allRowsSelected"
                      @click="toggleAllCheckboxes" />
                    <div>{{ $lang.general_Profile }}</div>
                  </div>
                </th>
                <th
                  scope="col"
                  class="pr-2 py-3 text-left text-xs font-medium text-grey-500 whitespace-nowrap">
                  <div
                    class="flex items-center cursor-pointer"
                    @click="sortByColumn('lastName')">
                    <div class="flex-none">{{ $lang.general_LastName }}</div>
                    <div class="ml-2 h-5 w-5 flex-none">
                      <sort-icon :active="activeSort === 'lastName'" />
                    </div>
                  </div>
                </th>
                <th
                  scope="col"
                  class="pr-2 py-3 text-left text-xs font-medium text-grey-500 whitespace-nowrap">
                  <div
                    class="flex items-center cursor-pointer"
                    @click="sortByColumn('firstName')">
                    <div class="flex-none">{{ $lang.general_FirstName }}</div>
                    <div class="ml-2 h-5 w-5 flex-none">
                      <sort-icon :active="activeSort === 'firstName'" />
                    </div>
                  </div>
                </th>
                <th
                  scope="col"
                  class="pr-2 py-3 text-left text-xs font-medium text-grey-500 whitespace-nowrap">
                  <div
                    class="flex items-center cursor-pointer"
                    @click="sortByColumn('isActive', 'boolean')">
                    <div class="flex-none">
                      {{ $lang.ambassadorPage_ProfileVisibleTableHeader }}
                    </div>
                    <div class="ml-2 h-5 w-5 flex-none">
                      <sort-icon :active="activeSort === 'isActive'" />
                    </div>
                  </div>
                </th>
                <th
                  scope="col"
                  class="pr-2 py-3 text-left text-xs font-medium text-grey-500 whitespace-nowrap">
                  <div
                    class="flex items-center cursor-pointer"
                    @click="sortByColumn('requestCall', 'boolean')">
                    <div class="flex-none">
                      {{ $lang.ambassadorPage_CallRequestsTableHeader }}
                    </div>
                    <div class="ml-2 h-5 w-5 flex-none">
                      <sort-icon :active="activeSort === 'requestCall'" />
                    </div>
                  </div>
                </th>
                <th
                  scope="col"
                  class="pr-4 py-3 text-left text-xs font-medium text-grey-500 whitespace-nowrap">
                  <div
                    class="flex items-center cursor-pointer"
                    @click="sortByColumn('status')">
                    <div class="flex-none">
                      {{ $lang.ambassadorPage_AccountStatusTableHeader }}
                    </div>
                    <div class="ml-2 h-5 w-5 flex-none">
                      <sort-icon :active="activeSort === 'requestCall'" />
                    </div>
                  </div>
                </th>
                <th
                  scope="col"
                  class="pr-2 py-3 text-left text-xs font-medium text-grey-500 whitespace-nowrap">
                  <div
                    class="flex items-center w-max cursor-pointer"
                    @click="sortByColumn('conversationCount', 'number')">
                    <div class="flex-none">
                      {{ $lang.general_Conversations }}
                    </div>
                    <div class="ml-2 h-5 w-5 flex-none">
                      <sort-icon :active="activeSort === 'requestCall'" />
                    </div>
                  </div>
                </th>
                <th
                  scope="col"
                  class="pr-2 py-3 text-left text-xs font-medium text-grey-500 whitespace-nowrap">
                  <div
                    class="flex items-center w-max"
                    :content="$lang.ambassadorPage_ProspectConnectionsTooltip"
                    v-tippy="{
                      placement: 'bottom',
                      arrow: true,
                      maxWidth: '500px',
                    }">
                    <div class="flex-none">
                      {{ $lang.ambassadorPage_ProspectConnectionsTableHeader }}
                    </div>
                  </div>
                </th>
                <th />
              </tr>
            </thead>

            <!-- Rows -->
            <tbody class="font-normal text-sm">
              <tr
                class="border-b h-14 transition ease group"
                :class="{
                  'bg-blue-100 bg-opacity-50': selectedRows[ambassador.id],
                  hidden: emptySearchOrFilterResults,
                }"
                v-for="(ambassador, index) of displayedAmbassadors"
                v-bind:key="`ambassador-row-${index}`">
                <td class="pr-10">
                  <div class="ml-2 flex items-center">
                    <input
                      v-if="
                        ambassador.status !== 'Invited' &&
                        ambassador.status !== 'Archived'
                      "
                      type="checkbox"
                      :checked="selectedRows[ambassador.id]"
                      :value="selectedRows[ambassador.id]"
                      @click="(e) => toggleCheckbox(e, ambassador)" />
                    <div
                      v-else
                      :content="$lang.ambassadorPage_BulkActionTooltip"
                      v-tippy="{
                        placement: 'right',
                        arrow: true,
                        maxWidth: '500px',
                      }">
                      <input disabled type="checkbox" />
                    </div>
                    <div
                      v-if="ambassador.profileImage"
                      class="flex-shrink-0 ml-4 rounded-full overflow-hidden">
                      <img
                        :src="ambassador.profileImage"
                        class="w-8 h-8 object-cover" />
                    </div>
                    <div
                      v-else
                      class="w-8 h-8 ml-4 bg-grey-200 rounded-full whitespace-nowrap" />
                  </div>
                </td>
                <td
                  class="pr-5 lg:pr-1 transition ease"
                  :class="{
                    'group-hover:text-blue cursor-pointer':
                      ambassador.status !== 'Invited',
                  }"
                  @click="goToAmbassadorProfile(ambassador)">
                  {{ ambassador.lastName }}
                </td>
                <td
                  class="pr-5 lg:pr-1 transition ease"
                  :class="{
                    'group-hover:text-blue cursor-pointer':
                      ambassador.status !== 'Invited',
                  }"
                  @click="goToAmbassadorProfile(ambassador)">
                  {{ ambassador.firstName }}
                </td>
                <td class="pr-5 lg:pr-1">
                  <div class="ml-6">
                    <common-toggle
                      v-if="
                        ambassador.status !== 'Invited' &&
                        ambassador.status !== 'Archived' &&
                        ambassador.status !== 'Vacation'
                      "
                      :value="ambassador.isActive"
                      @input="
                        setAmbassador(ambassador, {
                          isActive: !ambassador.isActive,
                        })
                      " />

                    <img
                      :src="require('@/assets/lock-icon.svg')"
                      :title="
                        ambassador.status === 'Vacation'
                          ? $lang.ambassadorPage_IsOnVacationProfileDisabledTitle
                          : ''
                      "
                      v-else />
                  </div>
                </td>
                <td class="pr-5 lg:pr-1">
                  <div class="ml-6">
                    <common-toggle
                      v-if="
                        ambassador.status !== 'Invited' &&
                        ambassador.status !== 'Archived'
                      "
                      :value="ambassador.requestCall"
                      @input="
                        setAmbassador(ambassador, {
                          requestCall: !ambassador.requestCall,
                        })
                      " />

                    <img :src="require('@/assets/lock-icon.svg')" v-else />
                  </div>
                </td>
                <td class="pr-5 lg:pr-1" tabindex="0">
                  <div class="relative group ml-2">
                    <div class="flex w-full items-center">
                      <div
                        :class="[
                          `status-dropdown status-${ambassador.status.toLowerCase()}`,
                        ]">
                        {{ $lang[`ambassadorPage_Status${ambassador.status}`] }}
                      </div>
                    </div>
                  </div>
                </td>

                <!-- Conversations -->
                <td>
                  <div class="flex items-center w-full ml-8">
                    <div>{{ ambassador.conversationCount }}</div>
                    <div
                      v-if="checkTypeOf(ambassador, 'number')"
                      class="animate-pulse">
                      <div class="w-2 h-2 bg-grey-300 rounded-lg" />
                    </div>
                  </div>
                </td>

                <!-- Prospect Connections -->
                <td class="pr-5 lg:pr-1">
                  <div class="flex text-grey">
                    <!-- Call Outreach -->
                    <div class="flex items-center mr-7 w-8">
                      <img
                        :src="require('@/assets/call-icon.svg')"
                        class="h-4 w-4 mr-2 flex-none" />

                      {{ ambassador.callOutreachRequests }}
                    </div>

                    <!-- Email Outreach -->
                    <div class="flex items-center mr-7 w-8">
                      <img
                        :src="require('@/assets/email-icon.svg')"
                        class="h-4 w-4 mr-2 flex-none" />

                      {{ ambassador.emailOutreachRequests }}
                    </div>

                    <!-- Video Outreach -->
                    <div class="flex items-center mr-10 w-8">
                      <img
                        :src="require('@/assets/video-icon.svg')"
                        class="h-4 w-4 mr-2 flex-none" />

                      {{ ambassador.videoOutreachRequests }}
                    </div>
                  </div>
                </td>

                <td>
                  <common-dropdown
                    class="more-options-menu"
                    variant=""
                    :options="
                      getAmbassadorMoreOptions(ambassador.status.toLowerCase())
                    "
                    @click.native="storeAmbassadorIndex(ambassador)"
                    @input="moreMenuOptions[$event].callback(ambassador)"
                    alignDropdownLeft
                    maxHeight
                    :button="{
                      variant: 'icon',
                      icon: true,
                      iconOnly: true,
                    }">
                    <template slot="icon">
                      <img
                        class="more-icon"
                        :src="require('@/assets/ellipsis-icon.svg')" />
                    </template>
                  </common-dropdown>
                </td>
              </tr>

              <!-- Skeleton Loading -->
              <tr
                v-for="(n, index) in 6"
                class="border-b h-14 animate-pulse"
                :class="{
                  hidden: ambassadors.length || noAmbassadorsToDisplay,
                }"
                v-bind:key="`skeleton-row-${index}`">
                <td class="pr-5">
                  <div class="w-2/3 h-4 mx-2 bg-grey-200 rounded-lg" />
                </td>
                <td class="pr-5">
                  <div class="w-2/3 h-4 mx-2 bg-grey-200 rounded-lg" />
                </td>
                <td class="pr-5">
                  <div class="w-2/3 h-4 mx-2 bg-grey-200 rounded-lg" />
                </td>
                <td class="pr-5">
                  <div class="w-2/3 h-4 mx-2 bg-grey-200 rounded-lg" />
                </td>
                <td class="pr-5">
                  <div class="w-2/3 h-4 mx-2 bg-grey-200 rounded-lg" />
                </td>
                <td class="pr-5">
                  <div class="w-2/3 h-4 mx-2 bg-grey-200 rounded-lg" />
                </td>
                <td class="pr-5">
                  <div class="w-2/3 h-4 mx-2 bg-grey-200 rounded-lg" />
                </td>
              </tr>

              <!-- No ambassadors for this organization -->
              <tr class="h-14" :class="{ hidden: !noAmbassadorsToDisplay }">
                <td class="pl-2">
                  <div class="text-grey-500">
                    {{ $lang.ambassadorPage_NoAmbassadors }}
                  </div>
                </td>
              </tr>

              <!-- Empty filter or search -->
              <tr class="h-14" :class="{ hidden: !emptySearchOrFilterResults }">
                <td class="pl-2 flex">
                  <div class="text-grey-500 flex-none">
                    {{ $lang.ambassadorPage_NoSearchResults }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>

    <!-- Footer -->
    <common-table-footer
      valueLabel="Ambassadors"
      :itemCount="sortedAmbassadors.length"
      :numberOfPages="numberOfPages"
      :currentPage="currentPage"
      :firstIndex="firstIndex"
      :lastIndex="lastIndex"
      @updateRowsPerPage="updateRowsPerPage"
      @updateCurrentPage="updateCurrentPage" />
  </div>
</template>

<script>
  import { exportCsvFile, exportSafeguardingReport } from "@/shared/utils.js";
  import { mapGetters } from "vuex";
  import {
    GET_CURRENT_PARENTS,
    GET_CALL_OUTREACH,
    GET_EMAIL_OUTREACH,
    GET_VIDEO_OUTREACH,
    GET_INVITED_AMBASSADORS,
    GET_CONVERSATIONS,
    GET_BRANDING,
    GET_LAST_FETCHED,
    GET_ACTIVE_YEAR,
  } from "@/store/getters.type";

  import {
    FETCH_CURRENT_PARENTS,
    SET_AMBASSADORS,
    ARCHIVE_AMBASSADORS,
    DELETE_AMBASSADOR,
    DELETE_INVITED_AMBASSADOR,
    FETCH_CONVERSATIONS,
    FETCH_INVITED_AMBASSADORS,
    REMIND_INVITED_AMBASSADOR,
    RESTORE_AMBASSADORS,
    SET_LAST_FETCHED,
  } from "@/store/actions.type";

  import screens from "@/constants/screens";
  import sortService from "@/services/sort.service.js";
  import dateService from "@/services/date.service.js";
  import ambassadorsService from "./ambassadors.service";
  const FilterDropdown = () =>
    import("./ambassadorsComponents/FilterDropdown.vue");
  const ProfileCard = () => import("./ambassadorsComponents/ProfileCard.vue");
  const EditProfileModal = () =>
    import("./ambassadorsComponents/EditProfileModal.vue");
  const SendMessageModal = () =>
    import("@/components/common/tailwind/modals/SendMessageModal.vue");
  const SendBulkMessageModal = () =>
    import("@/components/common/tailwind/modals/SendBulkMessageModal.vue");
  const RequestOutreachModal = () =>
    import("@/components/common/tailwind/modals/RequestOutreachModal.vue");
  const InviteAmbassadorModal = () =>
    import("@/components/common/tailwind/modals/InviteAmbassadorModal.vue");
  const ManageOrderModal = () =>
    import("./ambassadorsComponents/ManageOrderModal.vue");
  const EditStoryModal = () =>
    import("@/components/common/tailwind/modals/EditStoryModal.vue");

  export default {
    name: "ambassadors-screen",

    components: {
      FilterDropdown,
      ProfileCard,
      SendMessageModal,
      SendBulkMessageModal,
      RequestOutreachModal,
      InviteAmbassadorModal,
      EditProfileModal,
      ManageOrderModal,
      EditStoryModal,
    },

    data() {
      return {
        year: null,
        lastFetched: "",
        fetching: false,
        currentPage: 1,
        searchParameter: "",
        landingPageURL: "",
        rowsPerPage: 100,
        sortDirection: {},
        activeSort: "",
        mostRecentSortType: "",
        mostRecentFilter: null,
        selectedRows: {},
        allRowsSelected: false,
        // This index represents the index of the ambassador in whatever list is being displayed (full list, filtered list, searched list)
        ambassadorsListIndex: -1,
        sendMessageModalOpened: false,
        sendBulkMessageModalOpened: false,
        requestOutreachModalOpened: false,
        inviteAmbassadorModalOpened: false,
        manageOrderModalOpened: false,
        editProfileModalOpened: false,
        deleteAmbassadorPopupOpened: false,
        profileCardOpened: false,
        storyModalOpened: false,
        selectedAmb: {},

        bulkActionsDropdownOptions: [
          [
            {
              label: this.$lang.ambassadorPage_BulkActionOptionVisible,
              value: 0,
            },
            {
              label: this.$lang.ambassadorPage_BulkActionOptionInvisible,
              value: 1,
            },
          ],

          [
            {
              label: this.$lang.ambassadorPage_BulkActionOptionEnableCalls,
              value: 2,
            },
            {
              label: this.$lang.ambassadorPage_BulkActionOptionDisableCalls,
              value: 3,
            },
          ],
          [
            {
              label: this.$lang.ambassadorPage_BulkActionOptionArchive,
              value: 5,
            },
          ],
          [
            {
              label: this.$lang.ambassadorPage_BulkActionOptionMessage,
              value: 6,
            },
          ],
        ],

        quickMenuOptions: [
          {
            label: this.$lang.ambassadorPage_BulkActionOptionInvite,
            value: 0,
          },
          {
            label: this.$lang.ambassadorPage_BulkActionOptionViewLanding,
            value: 1,
          },
          {
            label: this.$lang.ambassadorPage_BulkActionOptionManageOrder,
            value: 2,
          },
          {
            label: this.$lang.ambassadorPage_BulkActionOptionExport,
            value: 3,
          },
        ],

        deleteAmbassadorPopupProps: {
          headerText: this.$lang.ambassadorPage_DeleteModalHeader,
          bodyText: this.$lang.ambassadorPage_DeleteModalBody,
          firstButton: {
            variant: "info",
            text: this.$lang.ambassadorPage_DeleteModalButtonCancel,
          },
          secondButton: {
            variant: "danger",
            text: this.$lang.ambassadorPage_DeleteModalButtonConfirm,
          },
        },
      };
    },

    computed: {
      firstIndex() {
        return (this.currentPage - 1) * this.rowsPerPage;
      },

      lastIndex() {
        return this.firstIndex + this.rowsPerPage <=
          this.sortedAmbassadors.length
          ? this.firstIndex + this.rowsPerPage
          : this.firstIndex + (this.sortedAmbassadors.length - this.firstIndex);
      },

      numberOfPages() {
        return Math.ceil(this.sortedAmbassadors.length / this.rowsPerPage);
      },

      dataFetchDisplayText() {
        return this.lastFetched
          ? this.$lang.ambassadorPage_FetchDisplay.format(
              this.lastFetched.toLocaleDateString(),
              this.lastFetched.toLocaleTimeString()
            )
          : this.$lang.ambassadorPage_FetchingDisplay;
      },

      hasProfileFilter() {
        return this.mostRecentFilter?.profile
          ? Object.keys(this.mostRecentFilter.profile).length
          : false;
      },

      hasCallRequestFilter() {
        return this.mostRecentFilter?.callRequests
          ? Object.keys(this.mostRecentFilter.callRequests).length
          : false;
      },

      hasAmbStatusFilter() {
        return this.mostRecentFilter?.status
          ? Object.keys(this.mostRecentFilter.status).length
          : false;
      },

      //Ambassadors are filtered into filteredAmbassadors
      //filterAmbassadors are searched on to create searchedAmbassadors
      //searchedAmbassadors are sorted on to create sortedAmbassadors
      //sortedAmbassadors are paginated to create displayedAmbassadors
      ambassadors() {
        if (this.fetching) {
          return [];
        }

        let allAmbassadors = [];
        const currentAmbassadors = this.getAmbassadors;
        const invitedAmbassadors = this.getInvitedAmbassadors;

        // Calculating # of call outreach requests for each ambassador
        let callOutreachRequests = {},
          emailOutreachRequests = {};

        if (
          this.getCallOutreach.pendingRequests &&
          this.getCallOutreach.completedRequests
        ) {
          for (let invite of [
            ...this.getCallOutreach.pendingRequests,
            ...this.getCallOutreach.completedRequests,
          ]) {
            if (invite.inviterID) {
              callOutreachRequests[invite.inviterID._id]
                ? (callOutreachRequests[invite.inviterID._id] += 1)
                : (callOutreachRequests[invite.inviterID._id] = 1);
            }
          }
        }

        // Calculating # of email outreach requests for each ambassador
        if (this.getEmailOutreach) {
          for (let invite of this.getEmailOutreach) {
            if (invite.inviterID) {
              emailOutreachRequests[invite.inviterID._id]
                ? (emailOutreachRequests[invite.inviterID._id] += 1)
                : (emailOutreachRequests[invite.inviterID._id] = 1);
            }
          }
        }

        // Calculating # of video outreach requests for each ambassador
        const videoOutreachRequests =
          ambassadorsService.calculateVideoOutreachCount(this.getVideoOutreach);

        // Add active ambassadors to full list of ambassadors
        for (let i = 0; i < currentAmbassadors.length; i++) {
          const ambassador = currentAmbassadors[i];

          const ambStatus = () => {
            if (ambassador.isArchived) {
              return "Archived";
            } else if (ambassador.isOnVacation) {
              return "Vacation";
            } else if (ambassador.isConvoLimited) {
              return "ConvoLimited";
            } else if (dateService.isOlderThan(ambassador.createdDate, 7)) {
              return "New";
            } else {
              return "Active";
            }
          };

          allAmbassadors.push({
            id: ambassador._id,
            firstName: ambassador.first,
            lastName: ambassador.last,
            email: ambassador.email,
            status: ambStatus(),
            isActive: ambassador.isActive,
            requestCall: ambassador.requestCall,
            profile: ambassador.profile,
            profileImage: ambassador.profileImage || ambassador.imageURL,
            callOutreachRequests: callOutreachRequests[ambassador._id] || 0,
            emailOutreachRequests: emailOutreachRequests[ambassador._id] || 0,
            videoOutreachRequests: videoOutreachRequests[ambassador._id] || 0,
            conversationCount: 0,
            FAQ: ambassador.FAQ,
            isConvoLimited: ambassador.isConvoLimited,
          });
        }

        // Add invited ambassadors to full list of ambassadors
        if (invitedAmbassadors.length) {
          for (let invite of invitedAmbassadors) {
            allAmbassadors.push({
              id: invite.id,
              firstName: invite.inviteeFirst,
              lastName: invite.inviteeLast,
              email: invite.inviteeEmail,
              status: "Invited",
              requestCall: null,
              profileImage: null,
              callOutreachRequests: 0,
              emailOutreachRequests: 0,
              videoOutreachRequests: 0,
              conversationCount: 0,
            });
          }
        }

        for (let ambassador of allAmbassadors) {
          let conversationCount = 0;

          if (this.getConversations?.messages?.length) {
            const conversations = this.getConversations.messages.filter(
              (val) => {
                return (
                  val.authorID === ambassador.id ||
                  val.recipID === ambassador.id
                );
              }
            );

            conversationCount = conversations.length;
          }

          ambassador.conversationCount = conversationCount;
        }

        return [...allAmbassadors];
      },

      filteredAmbassadors() {
        const filter = this.mostRecentFilter;

        // This function manually checks the filters in filterDropdown
        return this.ambassadors.filter((ambassador) => {
          let visible,
            notVisible,
            questionsAnswered,
            questionsNotAnswered,
            callRequestsEnabled,
            callRequestsDisabled;
          let profile = true,
            callRequests = true,
            ambStatus = true;

          if (this.hasProfileFilter) {
            if ("visible" in filter.profile)
              visible = ambassador.isActive == true;
            if ("notVisible" in filter.profile)
              notVisible = !!ambassador.isActive == false;
            if ("questionsAnswered" in filter.profile)
              questionsAnswered =
                ambassador.profile &&
                Object.keys(ambassador.profile).length === 4;
            if ("questionsNotAnswered" in filter.profile)
              questionsNotAnswered =
                !(
                  ambassador.profile &&
                  Object.keys(ambassador.profile).length === 4
                ) && ambassador.status !== "Invited";

            profile =
              visible ||
              notVisible ||
              questionsAnswered ||
              questionsNotAnswered;
          }

          if (this.hasCallRequestFilter) {
            if ("callRequestsEnabled" in filter.callRequests)
              callRequestsEnabled = ambassador.requestCall == true;
            if ("callRequestsDisabled" in filter.callRequests)
              callRequestsDisabled = !!ambassador.requestCall == false;

            callRequests = callRequestsEnabled || callRequestsDisabled;
          }

          if (this.hasAmbStatusFilter) {
            const ambassadorStatus = ambassador.status
              ? ambassador.status.toLowerCase()
              : null;
            ambStatus = ambassadorStatus in filter.status;
          }

          return profile && callRequests && ambStatus;
        });
      },

      searchedAmbassadors() {
        let ambs = this.filteredAmbassadors;

        if (this.searchParameter.length) {
          const keysToSearchBy = [
            "firstName",
            "lastName",
            "status",
            "conversationCount",
            "videoOutreachRequests",
            "callOutreachRequests",
            "emailOutreachRequests",
          ];

          ambs = sortService.searchObjectValuesByKeys(
            ambs,
            this.searchParameter,
            keysToSearchBy,
            "id"
          );
        }

        return ambs;
      },

      //Current filtered/searched/sorted ambassador list
      sortedAmbassadors() {
        return sortService.sortArrayOfObjects(
          this.searchedAmbassadors,
          this.activeSort,
          this.mostRecentSortType,
          this.sortDirection[this.activeSort]
        );
      },

      //Current paginated/filtered/searched/sorted ambassador list
      displayedAmbassadors() {
        return this.sortedAmbassadors.slice(
          this.firstIndex,
          this.firstIndex + this.rowsPerPage
        );
      },

      emptySearchOrFilterResults() {
        return (
          (this.searchParameter.length && !this.searchedAmbassadors.length) ||
          ((this.hasProfileFilter ||
            this.hasCallRequestFilter ||
            this.hasAmbStatusFilter) &&
            !this.filteredAmbassadors.length)
        );
      },

      noAmbassadorsToDisplay() {
        return (
          !this.emptySearchOrFilterResults &&
          this.lastFetched &&
          !this.fetching &&
          !this.displayedAmbassadors.length
        );
      },

      allowConversationExport() {
        return this.getBranding?.features?.allowConversationExport;
      },

      allowPrioritizeOnLandingPage() {
        return !this.getBranding?.hasRandomizedAmbassadors;
      },
      moreMenuOptions() {
        return {
          [this.$lang.ambassadorPage_ActionOptionViewProfile]: {
            label: this.$lang.ambassadorPage_ActionOptionViewProfile,
            statuses: ["active", "convolimited", "new", "vacation"],
            callback: (ambassador) => {
              this.selectedAmb = ambassador;
              this.profileCardOpened = true;
            },
            isActiveFeature: true,
          },
          [this.$lang.ambassadorPage_ActionOptionMessage]: {
            label: this.$lang.ambassadorPage_ActionOptionMessage,
            statuses: ["active", "convolimited", "new", "vacation"],
            callback: (ambassador) => {
              this.selectedAmb = ambassador;
              this.sendMessageModalOpened = true;
            },
            isActiveFeature: true,
          },
          [this.$lang.ambassadorPage_ActionOptionOutreach]: {
            label: this.$lang.ambassadorPage_ActionOptionOutreach,
            statuses: ["active", "convolimited", "new"],
            callback: (ambassador) => {
              this.selectedAmb = ambassador;
              this.requestOutreachModalOpened = true;
            },
            isActiveFeature: true,
          },
          [this.$lang.ambassadorPage_ActionOptionEditProfile]: {
            label: this.$lang.ambassadorPage_ActionOptionEditProfile,
            statuses: ["active", "convolimited", "new", "vacation"],
            callback: (ambassador) => {
              this.selectedAmb = ambassador;
              this.editProfileModalOpened = true;
            },
            isActiveFeature: true,
          },
          [this.$lang.ambassadorPage_ActionOptionEditStory]: {
            label: this.$lang.ambassadorPage_ActionOptionEditStory,
            statuses: ["active", "convolimited", "new", "vacation"],
            callback: (ambassador) =>
              this.toggleAmbassadorStoryModal(ambassador),
            isActiveFeature: true,
          },
          [this.$lang.ambassadorPage_ActionOptionArchive]: {
            label: this.$lang.ambassadorPage_ActionOptionArchive,
            statuses: ["active", "convolimited", "new", "vacation"],
            callback: () => this.toggleAmbassadorActive(false),
            isActiveFeature: true,
          },
          [this.$lang.ambassadorPage_ActionOptionActivate]: {
            label: this.$lang.ambassadorPage_ActionOptionActivate,
            statuses: ["archived"],
            callback: () => this.toggleAmbassadorActive(true),
            isActiveFeature: true,
          },
          [this.$lang.ambassadorPage_ActionOptionInviteReminder]: {
            label: this.$lang.ambassadorPage_ActionOptionInviteReminder,
            statuses: ["invited"],
            callback: (ambassador) => this.sendInviteReminder(ambassador.id),
            isActiveFeature: true,
          },
          [this.$lang.ambassadorPage_ActionOptionPrioritize]: {
            label: this.$lang.ambassadorPage_ActionOptionPrioritize,
            statuses: ["active", "convolimited", "new"],
            callback: (ambassador) => this.prioritizeOnLandingPage(ambassador),
            isActiveFeature: this.allowPrioritizeOnLandingPage,
          },
          [this.$lang.general_ExportSafeguarding]: {
            label: this.$lang.general_ExportSafeguarding,
            statuses: ["active", "convolimited", "new", "vacation"],
            callback: (ambassador) => this.exportConversations(ambassador),
            isActiveFeature: this.allowConversationExport,
          },
          [this.$lang.general_Delete]: {
            label: this.$lang.general_Delete,
            statuses: [
              "active",
              "convolimited",
              "new",
              "vacation",
              "archived",
              "invited",
            ],
            callback: (ambassador) =>
              this.toggleDeleteAmbassadorPopup(ambassador),
            isActiveFeature: true,
            class: "delete-option",
          },
        };
      },

      ...mapGetters({
        getAmbassadors: GET_CURRENT_PARENTS,
        getInvitedAmbassadors: GET_INVITED_AMBASSADORS,
        getCallOutreach: GET_CALL_OUTREACH,
        getEmailOutreach: GET_EMAIL_OUTREACH,
        getVideoOutreach: GET_VIDEO_OUTREACH,
        getConversations: GET_CONVERSATIONS,
        getBranding: GET_BRANDING,
        getLastFetched: GET_LAST_FETCHED,
        getActiveYear: GET_ACTIVE_YEAR,
      }),
    },

    watch: {
      getLastFetched(recentFetch) {
        this.lastFetched = recentFetch.ambassadors;

        if (this.fetching) {
          this.$toasted.show(
            this.$lang.ambassadorPage_ToastTableRefresh,
            this.$toastedSuccess
          );
        }

        this.fetching = false;
      },

      searchParameter() {
        this.updateCurrentPage(1);
      },

      mostRecentFilter: {
        deep: true,
        handler() {
          this.updateCurrentPage(1);
        },
      },
    },

    async created() {
      this.year = this.getActiveYear;
      this.lastFetched = this.getLastFetched.ambassadors;

      if (this.getBranding.url) {
        this.landingPageURL = this.getBranding.url.split("/#")[0];
      }
    },

    methods: {
      toggleAmbassadorStoryModal(amb) {
        if (this.storyModalOpened) {
          // about to close modal
          this.selectedAmb = {};
        } else {
          this.selectedAmb = amb;
        }

        this.storyModalOpened = !this.storyModalOpened;
      },

      updateAmbassadorStory(data) {
        this.selectedAmb.FAQ = data;
        this.selectedAmb = {};
      },

      updateCurrentPage(updatedPage) {
        this.currentPage = updatedPage;
      },

      updateRowsPerPage(rowsNumber) {
        this.updateCurrentPage(1);
        this.rowsPerPage = rowsNumber;
      },

      sortByColumn(propertyName, dataType = "string") {
        this.activeSort = propertyName;
        this.mostRecentSortType = dataType;
        this.$set(
          this.sortDirection,
          propertyName,
          !this.sortDirection[propertyName]
        );
      },

      updateFilter(filter) {
        //Clone filter to avoid reference being passed around
        this.mostRecentFilter = JSON.parse(JSON.stringify(filter));
      },

      checkTypeOf(ambassador, type) {
        return typeof ambassador.conversationCount !== type;
      },

      toggleCheckbox(e, ambassador) {
        const clickedAmbassadorIndex = this.ambassadors.indexOf(ambassador);
        const id = ambassador.id;
        const checked = e.target.checked;

        if (e.target) {
          if (checked) {
            this.$set(this.selectedRows, id, {
              checked,
              clickedAmbassadorIndex,
            });
            if (
              Object.keys(this.selectedRows).length === this.ambassadors.length
            )
              this.allRowsSelected = true;
          } else {
            if (
              Object.keys(this.selectedRows).length === this.ambassadors.length
            )
              this.allRowsSelected = false;
            this.$delete(this.selectedRows, id);
          }
        }
      },

      toggleAllCheckboxes(e) {
        const checked = e.target.checked;

        if (checked) {
          this.ambassadors.forEach((row, index) => {
            if (row.status !== "Invited" && row.status !== "Archived")
              this.$set(this.selectedRows, row.id, { checked, index });
          });
        } else {
          this.selectedRows = {};
        }
      },

      goToAmbassadorProfile(ambassador) {
        if (ambassador.status !== "Invited")
          this.$router.push({
            name: screens.PROFILE_SCREEN,
            params: { id: ambassador.id },
          });
      },

      rotateProfile(backwards) {
        if (this.ambassadorsListIndex === 0 && backwards)
          this.ambassadorsListIndex = this.ambassadors.length;
        if (
          this.ambassadorsListIndex + 1 > this.ambassadors.length - 1 &&
          !backwards
        )
          this.ambassadorsListIndex = -1;

        if (backwards) {
          const nextAmbassador =
            this.ambassadors[this.ambassadorsListIndex - 1];
          this.ambassadorsListIndex -= 1;

          // Here we skip over editing profiles that are archived or invited
          if (
            nextAmbassador.status === "Archived" ||
            nextAmbassador.status === "Invited"
          ) {
            return this.rotateProfile(backwards);
          } else {
            this.selectedAmb = nextAmbassador;
          }
        } else {
          const nextAmbassador =
            this.ambassadors[this.ambassadorsListIndex + 1];
          this.ambassadorsListIndex += 1;

          // Here we skip over editing profiles that are archived or invited
          if (
            nextAmbassador.status === "Archived" ||
            nextAmbassador.status === "Invited"
          ) {
            return this.rotateProfile(backwards);
          } else {
            this.selectedAmb = nextAmbassador;
          }
        }
      },

      storeAmbassadorIndex(ambassador) {
        this.ambassadorsListIndex = this.ambassadors.indexOf(ambassador);
      },

      archiveRestoreAmbassadors: async function (properties, archive) {
        let response;
        const updatedAmbassadors = Object.keys(this.selectedRows).map((id) => {
          return {
            id: id,
            ...properties,
          };
        });

        if (archive)
          response = await this.$store.dispatch(
            ARCHIVE_AMBASSADORS,
            updatedAmbassadors
          );
        else
          response = await this.$store.dispatch(
            RESTORE_AMBASSADORS,
            updatedAmbassadors
          );

        if (response) {
          this.$toasted.show(
            this.$lang.ambassadorPage_ToastArchivalToggle.format(
              updatedAmbassadors.length
            ),
            this.$toastedSuccess
          );
          this.toggleAllCheckboxes({ target: { checked: false } });
        } else {
          return this.$toasted.show(
            this.$lang.general_ToastError,
            this.$toastedFailure
          );
        }
      },

      setAmbassador: async function (ambassador, properties) {
        const alertMessages = {
          isActive: this.$lang.ambassadorPage_ToastAmbassadorUpdateVisibility,
          requestCall: this.$lang.ambassadorPage_ToastAmbassadorUpdateCalls,
        };

        const data = [
          {
            id: ambassador.id,
            ...properties,
          },
        ];

        const response = await this.$store.dispatch(SET_AMBASSADORS, data);

        if (response) {
          this.$toasted.show(
            alertMessages[Object.keys(properties)[0]],
            this.$toastedSuccess
          );
        } else {
          return this.$toasted.show(
            this.$lang.general_ToastError,
            this.$toastedFailure
          );
        }
      },

      setBulkAmbassadors: async function (properties, message) {
        const updatedActiveAmbassadors = Object.keys(this.selectedRows).map(
          (id) => {
            return {
              id: id,
              ...properties,
            };
          }
        );

        const response = await this.$store.dispatch(
          SET_AMBASSADORS,
          updatedActiveAmbassadors
        );
        if (response) {
          this.$toasted.show(message, this.$toastedSuccess);
          this.toggleAllCheckboxes({ target: { checked: false } });
        } else {
          return this.$toasted.show(
            this.$lang.general_ToastError,
            this.$toastedFailure
          );
        }
      },

      prioritizeOnLandingPage: async function (ambassador) {
        if (!ambassador.isActive)
          return this.$toasted.show(
            this.$lang.ambassadorPage_ToastAmbassadorUnableToPrioritize,
            this.$toastedFailure
          );
        let ambassadorToPrioritize;
        const { filteredIndexedAmbassadors } =
          await ambassadorsService.getIndexedAmbassadors();
        const ambassadorIDs = filteredIndexedAmbassadors.map((amb, index) => {
          if (amb.id === ambassador.id) ambassadorToPrioritize = index;
          return amb.id;
        });

        ambassadorIDs.splice(ambassadorToPrioritize, 1);
        ambassadorIDs.unshift(ambassador.id);

        const response = await ambassadorsService.updateIndexedAmbassadors(
          ambassadorIDs
        );
        if (response) {
          this.$toasted.show(
            this.$lang.ambassadorPage_ToastAmbassadorUpdateOrder,
            this.$toastedSuccess
          );
        } else {
          this.$toasted.show(
            this.$lang.ambassadorPage_ToastAmbassadorUpdateOrderFailure,
            this.$toastedFailure
          );
        }
      },

      deleteAmbassador: async function () {
        let response;
        const ambassador = this.ambassadors[this.ambassadorsListIndex];
        if (ambassador.status === "Invited")
          response = await this.$store.dispatch(
            DELETE_INVITED_AMBASSADOR,
            ambassador.id
          );
        else
          response = await this.$store.dispatch(
            DELETE_AMBASSADOR,
            ambassador.id
          );

        if (response) {
          this.deleteAmbassadorPopupOpened = false;
          this.$toasted.show(
            this.$lang.modal_AccountDeleted,
            this.$toastedSuccess
          );
        } else {
          return this.$toasted.show(
            this.$lang.general_ToastError,
            this.$toastedFailure
          );
        }
      },

      exportAmbassadorList() {
        const fields = [
          { label: this.$lang.general_LastName, value: "lastName" },
          { label: this.$lang.general_FirstName, value: "firstName" },
          { label: this.$lang.general_Email, value: "email" },
          {
            label: this.$lang.ambassadorPage_ProfileVisibleTableHeader,
            value: (row) => (row.isActive ? "Visible" : "Not Visible"),
          },
          {
            label: this.$lang.ambassadorPage_CallRequestsTableHeader,
            value: (row) => (row.requestCall ? "On" : "Off"),
          },
          {
            label: this.$lang.ambassadorPage_AccountStatusTableHeader,
            value: "status",
          },
          {
            label: this.$lang.ambassadorPage_ProspectConnectionsTableHeader,
            value: (row) =>
              row.emailOutreachRequests +
              row.callOutreachRequests +
              row.videoOutreachRequests,
          },
        ];

        exportCsvFile(this.ambassadors, "PeerPal_Ambassador_List.csv", {
          fields,
        });
      },

      toggleAmbassadorActive: async function (value) {
        const ambassador = this.ambassadors[this.ambassadorsListIndex];

        if (value) {
          await this.setAmbassador(ambassador, {
            isArchived: false,
          });
        } else {
          await this.setAmbassador(ambassador, {
            isArchived: true,
            isActive: false,
            requestCall: false,
          });
        }
      },

      toggleDeleteAmbassadorPopup(ambassador) {
        if (ambassador.status === "Invited") {
          this.deleteAmbassadorPopupProps.headerText =
            this.$lang.ambassadorPage_DeleteModalInviteHeader.format(
              `${this.ambassadors[this.ambassadorsListIndex].firstName} ${
                this.ambassadors[this.ambassadorsListIndex].lastName
              }`
            );

          this.deleteAmbassadorPopupProps.bodyText =
            this.$lang.ambassadorPage_DeleteModalInviteBody.format(
              `${this.ambassadors[this.ambassadorsListIndex].firstName} ${
                this.ambassadors[this.ambassadorsListIndex].lastName
              }`
            );
        } else {
          this.deleteAmbassadorPopupProps.headerText =
            this.$lang.ambassadorPage_DeleteModalSpecificAccountHeader.format(
              `${this.ambassadors[this.ambassadorsListIndex].firstName} ${
                this.ambassadors[this.ambassadorsListIndex].lastName
              }`
            );

          this.deleteAmbassadorPopupProps.bodyText =
            this.$lang.ambassadorPage_DeleteModalBody;
        }

        this.deleteAmbassadorPopupOpened = true;
      },

      sendInviteReminder: async function (ambassadorInviteID) {
        const response = await this.$store.dispatch(
          REMIND_INVITED_AMBASSADOR,
          ambassadorInviteID
        );
        if (response) {
          this.$toasted.show(
            this.$lang.ambassadorPage_ToastInviteSent,
            this.$toastedSuccess
          );
        } else {
          return this.$toasted.show(
            this.$lang.ToastError,
            this.$toastedFailure
          );
        }
      },

      handleBulkDropdownClick: async function (option) {
        if (!Object.keys(this.selectedRows).length)
          return this.$toasted.show(
            this.$lang.ambassadorPage_BulkActionNoRowsSelected,
            this.$toastedFailure
          );
        switch (option) {
          case 0:
            return await this.setBulkAmbassadors(
              { isActive: true },
              this.$lang.ambassadorPage_BulkActionOptionVisibleClick.format(
                Object.keys(this.selectedRows).length
              )
            );
          case 1:
            return await this.setBulkAmbassadors(
              { isActive: false },
              this.$lang.ambassadorPage_BulkActionOptionInvisibleClick.format(
                Object.keys(this.selectedRows).length
              )
            );
          case 2:
            return await this.setBulkAmbassadors(
              { requestCall: true },
              this.$lang.ambassadorPage_BulkActionOptionEnableCallsClick.format(
                Object.keys(this.selectedRows).length
              )
            );
          case 3:
            return await this.setBulkAmbassadors(
              { requestCall: false },
              this.$lang.ambassadorPage_BulkActionOptionDisableCallsClick.format(
                Object.keys(this.selectedRows).length
              )
            );
          case 4:
            return await this.archiveRestoreAmbassadors({ isArchived: false });
          case 5:
            return await this.archiveRestoreAmbassadors(
              { isArchived: true, isActive: false, requestCall: false },
              true
            );
          case 6:
            return (this.sendBulkMessageModalOpened = true);
        }
      },

      handleQuickMenuClick(option) {
        switch (option) {
          case 0:
            return (this.inviteAmbassadorModalOpened = true);
          case 1:
            return window.open(this.landingPageURL);
          case 2:
            return (this.manageOrderModalOpened = true);
          case 3:
            return this.exportAmbassadorList();
        }
      },

      forceFetchAllData() {
        this.fetching = true;
        this.$store.dispatch(FETCH_CURRENT_PARENTS).data;
        this.$store.dispatch(FETCH_INVITED_AMBASSADORS).data;
        this.$store.dispatch(FETCH_CONVERSATIONS);
        this.$store.dispatch(SET_LAST_FETCHED, {
          ambassadors: new Date(),
        });
      },

      fetchMostRecent() {
        this.forceFetchAllData();
      },

      async exportConversations(ambassador) {
        if (!(await exportSafeguardingReport(ambassador.id))) {
          this.$toasted.info(this.$lang.ambassadorPage_SafeguardingNoMessages);
        }
      },

      getAmbassadorMoreOptions(status) {
        return Object.values(this.moreMenuOptions).filter(
          (option) => option.isActiveFeature && option.statuses.includes(status)
        );
      },
    },
  };
</script>

<style lang="scss" scoped>
  .status-new,
  .status-invited {
    color: $blue-400;
    background-color: $blue-100;
  }

  .status-active {
    color: $green-400;
    background-color: $green-100;
  }

  .status-archived,
  .status-convolimited,
  .status-vacation {
    color: $pink-400;
    background-color: $pink-100;
  }

  .status-dropdown {
    border-radius: 28px;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.25rem;
    width: 110px;
    height: 32px;
    padding: 5px;
    text-align: center;
  }

  ::v-deep {
    .common-dropdown-option.delete-option.select {
      color: $salmon-400;

      &:hover {
        color: $salmon-400;
        background-color: $pink-100;
      }
    }
  }

  .more-icon {
    width: 1rem;
    height: 1rem;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
</style>
